import { module } from 'modujs';
import modularLoad from 'modularload';
import { html, body } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        html.classList.add('is-first-load');

        this.load = new modularLoad({
            enterDelay: 610,
            transitions: {
                customTransition: {}
            }
        });

        this.load.on('loaded', (transition, oldContainer, newContainer) => {
            body.setAttribute('data-template', newContainer.getAttribute('data-template'))

            html.classList.remove('is-changing-page');
            html.classList.remove('is-scrolling-down');
            this.call('destroy', oldContainer, 'app');
            this.call('update', newContainer, 'app');
        });

        this.load.on('loading', (transition, oldContainer) => {
            html.classList.add('is-changing-page');
            html.classList.remove('is-scrolling-down');
        })

        this.load.on('images', () => {
            this.call('update', 'Scroll')
        })
    }
}
