import { module } from 'modujs';
import { html } from '../utils/environment'

const CLASS = {
    INFOHOVER: `has-info-hover`,
}

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            mouseenter: 'open',
            mouseleave: 'close'
        }
    }

    init(){
        this.isOpen = false
    }

    open() {
        html.classList.add(CLASS.INFOHOVER)
    }

    close() {
        html.classList.remove(CLASS.INFOHOVER)
    }
}
