import { module } from 'modujs';
import { html } from '../utils/environment'

const CLASS = {
    LOGOHOVER: `has-logo-hover`,
}

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            mouseenter: 'open',
            mouseleave: 'close'
        }
    }

    init(){
        this.isOpen = false
    }

    toggleLogo() {
        if(this.isOpen) {
            this.close()
        } else {
            this.open()
        }
    }
    
    open() {
        html.classList.add(CLASS.LOGOHOVER)
    }

    close() {
        html.classList.remove(CLASS.LOGOHOVER)
    }
}
