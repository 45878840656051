import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        //thumbnail src
        this.featuredImageSrc = this.getData('featured-image-src')

        //array of images
        this.previewImages = Array.from(this.$('preview-image'))
    }

    init() {
        this.featuredImageLoaded = false
        this.previewImagesLoaded = false

        if(window.isMobile) return

        if(this.previewImages.length && !window.isMobile) {
            this.mouseenterBind = this.manageProjectHover.bind(this)
            this.el.addEventListener('mouseenter', this.mouseenterBind)

            this.mouseleaveBind = this.manageProjectLeave.bind(this)
            this.el.addEventListener('mouseleave', this.mouseleaveBind)
        }
    }


    /**
     * Manage images load and fade it
     *
     * @param
     * @return
     */
    manageImgLoad() {
        if(!this.featuredImageLoaded) {
            let image = new Image();

            image.onload = () => {
                setTimeout(() => {
                    this.$('featured-image')[0].src = this.featuredImageSrc;

                    this.el.classList.add('-lazy-loaded')
                }, 250);
            }

            image.src = this.featuredImageSrc;

            this.featuredImageLoaded = true
        }
    }

    /**
     * Load preview thumbnails
     */
    loadPreviewThumbnails() {
        this.previewImages.forEach((el) => {
            let style = el.dataset.style

            el.setAttribute('style', style)
        })

        this.previewImagesLoaded = true
    }

    /**
     * Manage project hover
     */
    manageProjectHover(e) {
        if(!this.previewImagesLoaded && this.previewImages.length && !window.isMobile) this.loadPreviewThumbnails()

        this.tl = new TimelineMax({ repeat: -1 });

        this.tl.staggerFromTo(this.previewImages, 0.5, { display: 'none' }, { display: 'block' }, 0.5)
    }

    /**
     * Manage project leave
     */
    manageProjectLeave(e) {
        this.tl.kill()
        this.tl = null

        this.previewImages.forEach((el) => {
            el.style.display = 'none'
        })
    }

    destroy() {
        if(!window.isMobile) {
            if(this.previewImages.length) {
                this.el.removeEventListener('mouseenter', this.mouseenterBind)
                this.el.removeEventListener('mouseleave', this.mouseleaveBind)
            }
        }
    }
}
