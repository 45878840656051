import { module } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';
import { html } from '../utils/environment'

export default class extends module {
    constructor(m) {
        super(m);

        window.scrollObj = {
            scroll: {
                y:0, x:0
            },
            limit: 0
        }
    }

    init() {
        setTimeout(() => {
            this.scroll = new LocomotiveScroll({
                el: this.el,
                smooth: true,
                getDirection: true
            });

            window.scroll = this.scroll;

            this.scroll.on('call', (func,way,obj,id) => {
                // Using modularJS
                this.call(func[0],{way,obj},func[1],func[2]);
            });

            this.scroll.on('scroll', (instance) => {
                window.scrollObj = instance

                if(instance.scroll.y > 50) {
                    html.classList.add('has-scrolled');
                } else {
                    html.classList.remove('has-scrolled');
                }

                if(instance.direction == 'down') {
                    html.classList.add('is-scrolling-down')
                } else {
                    html.classList.remove('is-scrolling-down')
                }

                if(instance.scroll.y > instance.limit - 50) {
                    html.classList.add('is-scrolling-end')
                } else {
                    html.classList.remove('is-scrolling-end')
                }
            })
        }, 400)
    }

    toggleLazy(args) {
        let src = this.getData('lazy', args.obj.el)
        if(src.length) {
            if(args.obj.el.tagName == "IMG") {
                args.obj.el.src = src
            } else {
                args.obj.el.style.backgroundImage = `url(${src})`
            }
            this.setData('lazy', '', args.obj.el)
        }
    }

    update() {
        if(this.scroll && this.scroll.update) this.scroll.update()
    }

    destroy() {
        this.scroll.destroy();
    }
}
